<template>
  <div class="s-footer-shops-container">
    <div class="footer-title mb-2">Shop By Category</div>
    <div class="flex flex-col md:flex-row">
      <div
        v-for="category of GET_HEADER_DATA.footer_category"
        :key="category.footer_category_title"
        class="s-footer-shop-category cursor-pointer"
      >
        <SalveLink
          v-if="!category.footer_category_title.includes('Merch')"
          :to="getCategoryLink(category.footer_category_url)"
          class="mr-0 md:mr-5 mb-5 md:mb-0"
        >
          <SalveImage
            :url="category.footer_category_image.url"
            :modifiers="{ w: 160 }"
            alt="preroll_icon"
            class="footer-category"
            loading="lazy"
          />
          <div class="s-category-text">
            {{ category.footer_category_title }}
          </div>
        </SalveLink>
        <a
          v-if="category.footer_category_title.includes('Merch')"
          :href="category.footer_category_url"
          target="_blank"
        >
          <SalveImage
            :url="category.footer_category_image.url"
            :modifiers="{ w: 160 }"
            alt="Shop by category"
            class="footer-category"
            loading="lazy"
          />
          <div class="s-category-text">
            {{ category.footer_category_title }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA'])
    },
    methods: {
      getCategoryLink(link) {
        // If this is a full hyperlink just return as is
        if (link.slice(0, 4) === 'http') {
          return link
        }

        // This is a shop link so pass back as a relative path from shop
        return link
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-shops-container {
    @apply flex flex-col justify-center md:justify-start text-center md:text-left mt-10 md:mt-0 mb-10 ml-0 md:ml-10;

    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-black mb-5;
    }

    .s-footer-shop-category {
      @apply text-center md:text-left w-full;
      a {
        @apply block mr-0 md:mr-5;
      }
      img {
        height: 80px;
        @apply hidden md:block;
      }
      .s-category-text {
        color: #000;
        @apply font-alt;
        @apply font-semibold mt-0 text-center;
      }
    }
  }
</style>
