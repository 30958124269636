<template>
  <div class="s-footer-navigation-contacts">
    <div class="s-footer-navigation-contacts__wrapper">
      <PageFooterContacts />
      <PageFooterMediaLinks />
      <div class="s-footer-navigation-contacts__wrapper_order">
        <PageFooterSocialLinks />
      </div>
    </div>
  </div>
</template>

<script>
  export default {}
</script>

<style scoped lang="postcss">
  .s-footer-navigation-contacts {
    display: flex;
    @apply font-alt;
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
    text-align: left;
    @apply font-bold;

    @media screen and (max-width: 1024px) {
      font-size: 14px;
    }

    &__wrapper {
      width: 100%;

      margin-top: 150px;
      display: flex;
      flex-direction: column;

      &_order {
        width: 100%;

        @media screen and (max-width: 768px) {
          order: -1;
        }
      }

      @media screen and (max-width: 768px) {
        margin-top: 0;
      }

      @media screen and (max-width: 545px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
</style>
