<template>
  <div class="s-footer-logos-container">
    <div class="footer-title mb-5">Partner Brands</div>
    <div class="grid grid-cols-2 gap-5 md:flex-row text-center justify-center items-center md:items-start">
      <div
        v-for="(brands, index) of GET_HEADER_DATA.footer_brands"
        :key="index"
        class="s-footer-partner-logo"
      >
        <NuxtLink :to="brands.footer_brand_url">
          <SalveImage
            :url="brands.footer_brand_image"
            alt="The Flowery partners"
            loading="lazy"
            width="174"
          />
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    data() {
      return {}
    },
    computed: {
      ...mapGetters(['GET_HEADER_DATA'])
    }
  }
</script>

<style scoped lang="postcss">
  .s-footer-logos-container {
    @apply flex flex-col justify-center md:justify-start text-center md:text-left;

    .footer-title {
      @apply font-main;
      font-weight: 700;
      text-transform: uppercase;
      @apply text-black;
    }

    .s-footer-partner-logo {
      @apply px-3 md:px-0 md:mr-10 items-center;
      img {
        width: 200px;
        object-fit: contain;
        max-height: 150px;
      }
    }
  }
</style>
